
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store";
import OverlayModule from "@/store/modules/overlay.module";
import { PromotionPlans } from "@/models/entities/subscription-plan.interface";
import { subscriptionPlansService } from "@/services/api/subscription-plan.service";
import { paymentsService } from "@/services/api/payments.service";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component
export default class CancelPlan extends Vue {
  private expiration = "";
  private subscriptionPlan: PromotionPlans = {
    offertID: 0,
    nameOffert: "",
    descrOffert: "",
    price: "",
    monthlyBeneficiaries: "",
    totalSubjects: "",
    firstSupport: false,
    secondSupport: false,
    freeTrial: "",
    expiration: "",
    roleId: 0,
    priceMonthID: "",
    priceYearID: "",
    yearPrice: "",
  };

  private async created() {
    overlayModule.showOverlay();
    this.subscriptionPlan = (
      await subscriptionPlansService.GetById(authModule.profileRoleID)
    ).data;
    this.expiration = (await subscriptionPlansService.GetExpiration()).data;
  }

  private async cancelSubscription() {
    overlayModule.showOverlay();
    await paymentsService.CancelSubscription();
    this.$router.push({
      name: "confirmationpage",
    });
  }

  private back() {
    this.$emit("back");
  }
}
